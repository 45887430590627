import React from 'react';

const Logo = ({ img }) => {
  return (
    <div className="logo">
      <img alt="logo" src={img} />
    </div>
  )
}

export default Logo
