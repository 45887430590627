import React from 'react'
import { Alert, Button, Modal, Select, Spin, Icon } from 'antd'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import axios from 'axios'
import { pathOr } from 'ramda'
import { fbFunctionsURL } from '../../firebase.js'
import { withRouter } from 'react-router';
const resetState = () => ({type: '@@reduxFirestore/CLEAR_DATA', preserve: { data: ['companyIds'], ordered: true }})


const confirm = Modal.confirm;
const Option = Select.Option;

class InviteMessage extends React.Component {
  state = {
    affiliateInviteId: null,
    selectedAffiliatedCompany: null,
    selectedCompany: null,
    accepting: false,
    rejecting: false
  }

  companySelect;

  getConfirmContent = (t) => {
    let { companies } = this.props
    let defaultVal;
    if (companies) {
      companies = Object.entries(companies)
      defaultVal = companies[0][0]
      this.setState({selectedCompany: defaultVal})
    }
    return (
    <div>
      <p>{t('Select which company you would like to affiliate')}</p>
      <Select style={{width: 300}} onChange={(val) => this.setState({selectedCompany: val})} defaultValue={defaultVal}>
        {companies && companies.map( company => (
          <Option value={company[0]}>{company[1].name}</Option>
        ))}
      </Select>
    </div>
    )
  }

  showConfirm(id, company, t) {
    confirm({
      title: t('Accept'),
      content: t('Are you sure you want to accept the invitation?'),
      onOk: () => {
        this.doAccept(id, company).then(() => this.props.history.replace('/'))
      },
      onCancel() {}
    });
  }

  doAccept = (id, company) => {
    this.setState({accepting: true})
    return this.props.firebase.auth().currentUser.getIdToken()
      .then(token => {
        const config = {
          url: `${fbFunctionsURL}/acceptInvitation`,
          method: 'post',
          data: { id, affiliatedCompany: company },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        return new Promise((resolve, reject) => {
          axios(config)
            .then((response) => {
              const { data } = response;
              this.props.resetState()
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
  }

  doReject = (id, company) => {
    this.setState({rejecting: true})
    return this.props.firebase.auth().currentUser.getIdToken()
      .then(token => {
        const config = {
          url: `${fbFunctionsURL}/rejectInvitation`,
          method: 'post',
          data: { id, affiliatedCompany: company },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        return new Promise((resolve, reject) => {
          axios(config)
            .then((response) => {
              const { data } = response;
              this.props.resetState()
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
  }

  acceptAffiliation = (id, t) => {
    confirm({
      title: 'Accept',
      content: this.getConfirmContent(t),
      onOk:() => {
        // this.doAccept(id).then(() => this.props.history.replace('/'))
        this.doAccept(id, this.state.selectedCompany)
        .then(() => {
          this.props.resetState()
          this.props.history.replace('/')
        });
      },
      onCancel() {}
    });
    // let { affiliateInviteId, selectedAffiliatedCompany } = this.state;
    //
    //
  //   if (this.companySelect && selectedAffiliatedCompany == null) {
  //     selectedAffiliatedCompany = this.companySelect.props.defaultValue;
  //   }
  //   if (affiliateInviteId && selectedAffiliatedCompany) {
  //     this.setState({ confirmLoading: true });
  //     this.doAccept(affiliateInviteId, selectedAffiliatedCompany)
  //       .then(() => {
  //         this.setState({ affiliateInviteId: null, selectedAffiliatedCompany: null, confirmLoading: false })
  //       });
  //   }
  }

  showReject(id, t) {
    confirm({
      title: 'Reject',
      content: 'Are you sure you want to reject the invitation?',
      onOk:() => {
        this.doReject(id);
      },
      onCancel() {}
    });
  }

  getCompanyName = (key) => {
    return (this.props.active_company
    && this.props.active_company[key]
    && this.props.active_company[key].name) || key
  }

  render() {
    let ownerOf = []
    if (this.props.companies) {
      ownerOf = Object.keys(this.props.companies)
    }

    const {current_invitations, t} = this.props;

    return (
      <div>
        {current_invitations.map(invite => {
          const message = invite.type === 'affiliate'
            ? (
              <div>
                {ownerOf.length > 0
                  ?
                    <div>
                      {`Your company has been invited to be an affiliate for ${invite.companyName || invite.name }`}
                      {/* <Button type="primary" size="small" style={{ marginLeft: 5 }} onClick={() => this.setState({ affiliateInviteId: invite.key })}>Accept</Button> */}
                      <Button loading={this.state.accepting} type="primary" size="small" style={{ marginLeft: 5 }} onClick={() => this.acceptAffiliation(invite.key, t)}>Accept</Button>
                      <Button loading={this.state.rejecting} size="small" style={{ marginLeft: 5 }} onClick={() => this.showReject(invite.key, t)}>{t('Reject')}</Button>
                      {(this.state.rejecting || this.state.accepting) && <Spin style={{marginLeft: 10}} spin />}
                    </div>

                  : `${t('You have been invited to be an affiliate for')} ${invite.companyName || invite.name }. ${t('Setup your company first!')}`}
              </div>)
            : (
              <div>
                {`${t('You have been invited to join')} ${invite.companyName}.`}
                <Button type="primary" loading={this.state.accepting} size="small" style={{ marginLeft: 5 }} onClick={() => this.showConfirm(invite.key, invite.company, t)}>{t('Accept')}</Button>
                <Button size="small" loading={this.state.rejecting} style={{ marginLeft: 5 }} onClick={() => this.showReject(invite.key, t)}>{t('Reject')}</Button>
                {(this.state.rejecting || this.state.accepting) && <Spin style={{marginLeft: 10}} spin />}
              </div>)
          return <Alert key={invite.key} message={message} banner type={'info'} />;
        })}
        {ownerOf.length > 0 && current_invitations.length > 0 &&
          <Modal
            title={t("Select your company")}
            visible={this.state.affiliateInviteId != null}
            onOk={this.acceptAffiliation}
            onCancel={() => this.setState({ affiliateInviteId: null })}
            okText={t("Accept")}
            cancelText={t("cancel")}
            confirmLoading={this.state.confirmLoading}
          >
            <p>Select the company you want to make an affiliate:</p>
            <Select ref={companySelect => this.companySelect = companySelect} defaultValue={ownerOf[0].key} onChange={(selectedAffiliatedCompany) => this.setState({ selectedAffiliatedCompany })} style={{ width: '100%' }} >
              {ownerOf.map(company => (<Option key={company.key} value={company.key}>{this.getCompanyName(company.key)}</Option>))}
            </Select>
        </Modal>}
      </div>
    );
  }
}

const enhancer = compose(
  firestoreConnect((props, state) => {
    let current_user_email = pathOr(null, ['firebase', 'auth', 'email'], state.getState());
    //
    return [
      {
      collection: 'invites',
      where: [
        ['email', '==', current_user_email],
        ['status', '==', false]
      ],
      storeAs: 'invitations'
    }
    ];
  }),
  connect((state, props) => {
    let current_invitations = pathOr({}, ['firestore', 'data', 'invitations'], state);
    return {
      current_invitations: Object.entries(current_invitations).map(invite => ({ ...invite[1], key: invite[0] })),
      companies: state.firestore.data.companies
    }
  }, {resetState: resetState}),
)

export default withRouter(enhancer(InviteMessage))
