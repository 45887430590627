import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { reducer as formReducer } from 'redux-form'

const formData = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD':
      return Object.assign({}, state, {
        [action.formName]: action.data
      });
    default:
      return state
  }
}

const resetState = (state = {}, action) => {
  switch (action.type) {
    case 'RESET':
      state = undefined
      return appReducer(state, action)
    default:
      return state
  }
}

const appReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: formReducer,
})

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: formReducer,
    formData: formData,
    resetState: resetState
})

export default rootReducer
