import Loadable from 'react-loadable'
import Loading from './components/common-ui/loading'
import { DefaultLayout, AuthLayout, CompanyOverivew, ConfirmLayout } from './components/layouts'

const getComponent = (component) => (
  Loadable({
    loader: () => import(`./containers/${component}/${component}`),
    loading: Loading
  })
);

const confirmOrder = () => (
  Loadable({
    loader: () => import('./containers/orders/confirmOrder'),
    loading: Loading
  })
)
export const AUTHROUTE = 'signin'

export default [
  {
    key: 'redirection',
    exact: true,
    path: '/',
    private: true,
    layout: 'ConfirmLayout',
    component: getComponent('redirection'),
    menu: false,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  },
  {
    key: 'auth',
    exact: true,
    path: '/signin',
    private: true,
    layout: 'AuthLayout',
    component: getComponent('auth'),
    menu: false,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  }, {
    key: 'auth',
    exact: true,
    path: '/invitation/:inviteid/:companyid',
    private: true,
    layout: 'AuthLayout',
    component: getComponent('auth'),
    menu: false,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  }, {
    key: 'dashboard',
    label: 'DASHBOARD',
    icon: 'dashboard',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/dashboard',
    private: true,
    component: getComponent('dashboard'),
    menu: true,
    permission: ['owner', 'employee', 'editor']
  }, {
    key: 'companies',
    label: 'COMPANIES',
    icon: 'appstore',
    layout: 'CompanyOverview',
    exact: true,
    path: '/companies',
    private: true,
    component: getComponent('companies'),
    menu: true,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  }, {
    key: 'products',
    label: 'PRODUCTS',
    icon: 'tags',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/products',
    private: true,
    component: getComponent('products'),
    permission: ['owner', 'editor', 'employee'],
    menu: true
  }, {
    key: 'compare',
    label: 'CREATE AN ORDER',
    icon: 'shopping-cart',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/compare/:category',
    private: true,
    component: getComponent('compare'),
    menu: true,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  }, {
    key: 'orders',
    label: 'ORDERS',
    icon: 'line-chart',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/orders',
    private: true,
    component: getComponent('orders'),
    menu: true,
    permission: ['owner', 'employee', 'editor', 'kpnAgent']
  },{
    key: 'affiliates',
    label: ' RESELLERS',
    icon: 'cluster',
    layout: 'Default Layout',
    exact: true,
    path: '/company/:companyid/affiliates',
    private: true,
    component: getComponent('affiliates'),
    menu: true,
    permission: ['owner']
  }, {
    key: 'affiliate_details',
    label: 'AFFILIATE DETAILS',
    icon: 'cluster',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/affiliate/:company_id',
    private: true,
    component: getComponent('affiliatedetails'),
    menu: false,
    permission: ['owner']
  }, {
    key: 'companysetting',
    label: 'COMPANY SETTINGS',
    icon: 'setting',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/companysetting',
    private: true,
    component: getComponent('companysetting'),
    menu: false,
    permission: ['owner']
  },
  {
    key: 'setting',
    label: 'SETTING',
    icon: 'setting',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/setting',
    private: true,
    component: getComponent('settings'),
    menu: false,
    permission: ['owner', 'editor', 'employee']
  },
  {
    key: 'vendor',
    label: 'VENDORS',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/vendors',
    private: true,
    component: getComponent('vendors'),
    menu: true,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'contacts',
    label: 'CONTACTS',
    icon: 'team',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/contacts',
    private: true,
    component: getComponent('contacts'),
    menu: true,
    permission: ['owner', 'employee']
  },
  {
    key: 'productbuilder',
    label: 'PRODUCT BUILDER',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/product-builder/energy/:productId?',
    private: true,
    component: getComponent('productbuilder_energy'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'productbuilder',
    label: 'PRODUCT BUILDER',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/product-builder/lead/:productId?',
    private: true,
    component: getComponent('productbuilder_energy'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'templatebuilder',
    label: '',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/templatebuilder',
    private: true,
    component: getComponent('templatebuilder'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'productbuilder',
    label: 'PRODUCT BUILDER',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/product-builder/home/:productId?',
    private: true,
    component: getComponent('productbuilder_telecom'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'productbuilder',
    label: 'PRODUCT BUILDER',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/product-builder/mobile/:productId?',
    private: true,
    component: getComponent('productbuilder_telecom'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'productbuilder',
    label: 'PRODUCT BUILDER',
    icon: 'solution',
    layout: 'DefaultLayout',
    exact: false,
    path: '/company/:companyid/product-builder/mobile/:productId?',
    private: true,
    component: getComponent('productbuilder_telecom'),
    menu: false,
    permission: ['owner'],
    onlySuperResellers: true
  },
  {
    key: 'confirmedorder',
    label: 'CONFIRM ORDER',
    icon: 'solution',
    layout: 'ConfirmLayout',
    exact: true,
    path: '/confirm-order/:orderid',
    private: true,
    component: confirmOrder(),
    menu: false,
    permission: ['owner', 'employee', 'editor']
  },
  {
    key: 'chat',
    label: 'CHAT',
    icon: 'message',
    layout: 'DefaultLayout',
    exact: true,
    path: '/company/:companyid/chat/:orderId',
    private: true,
    component: getComponent('chat'),
    menu: false,
    permission: ['owner', 'editor']
  },
  {
    key: 'rating',
    label: 'DEBUG',
    icon: 'robot',
    layout: 'ConfirmLayout',
    exact: true,
    path: '/rating/:orderid/:rating',
    private: false,
    component: getComponent('rating'),
    menu: false,
    permission: ['owner', 'editor', 'employee']
  }
]
