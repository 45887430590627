import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from './store'
import history from './utils/history'
import App from './app'
import './i18n';
import { getTranslations } from './i18n'
import { unregister } from './registerServiceWorker'
import 'antd/dist/antd.css';

const store = configureStore()

const root = document.getElementById('root')

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <Component />
            </Router>
        </Provider>,
        root
    )
}

if (module.hot) {
    module.hot.accept('./app', () => {
        const NextApp = require('./app').default
        render(NextApp)
    })
}

store.firebaseAuthIsReady.then(() => {
  getTranslations('EN')
  getTranslations('NL')
  render(App)
})

unregister()
