import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import { getFirestore } from 'redux-firestore'

export const getTranslations = (lang) => {
  getFirestore().doc(`translations/${lang}`).get().then( snap => {
    let translations = snap.data()
    i18n.addResources(lang, 'test', translations);//JSON.stringify(translations))
  })
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: "NL",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

window.i18n = i18n;
export default i18n;
