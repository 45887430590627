import DefaultLayout from './DefaultLayout'
import AuthLayout from './AuthLayout'
import CompanyOverview from './CompanyOverview'
import ConfirmLayout from './ConfirmLayout'

const layouts = {
  DefaultLayout,
  AuthLayout,
  CompanyOverview,
  ConfirmLayout
}

export default layouts;
