import './components/common.css'
import './components/iconfont/flaticon.css'
import React, { Component, useState } from 'react'
import { Switch, Redirect } from 'react-router-dom'
// import DefaultLayout from './components/layouts/DefaultLayout'
import layouts from './components/layouts'
import { ConfigProvider, message, Modal } from 'antd';
import routes from './routes'
import nl_NL from 'antd/lib/locale-provider/nl_NL';
import en_GB from 'antd/lib/locale-provider/en_GB';
import { withNamespaces } from 'react-i18next';
import { getFirestore } from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'
import i18n from './i18n';
import moment from 'moment'
import axios from 'axios'
import './components/styles/buttons.css'
import './components/styles/form.scss'
import './components/styles/typography.css'
import packageJson from '../package.json';
global.appVersion = packageJson.version;

class App extends Component {

  state = { orderToOpen: null }

  componentDidMount = () => {
    this.getLocation()
    console.log('href', window.location.href)
    let showNotice = false//window.location.href.includes('telecom.') || window.location.href.includes('roms2-app.')
    if (showNotice) {
      Modal.confirm({
        title: 'Let op',
        autoFocusButton: null,
        content: 'This instance of Reach solely functions as a backup. Please use aanbod.reach.software for the latest version.',
        okText: 'Go to main version',
        onOk: () => window.location.replace('https://aanbod.reach.software')
      });
    }
    this.checkIfOnline()
  }

  checkIfOnline = () => {
    const { userLang } = this.props
    let uid = getFirebase().auth().currentUser && getFirebase().auth().currentUser.uid
    if (uid) {
      const ref = getFirebase().database().ref(`/sessions/${uid}`);
      ref.onDisconnect().set({ isOnline: false })
      ref.once('value').then(async (snapshot) => {
        const data = snapshot.val();
        if (data && data.isOnline) {
            let msg = userLang === 'EN'
              ? `This account already logged in at ${data.time} via IP-address ${data.ip} (estimated location: ${data.location}). There can only be one active session at a time. Not you? Please contact support`
              : `Dit account is al ingelogd om ${data.time} via IP-adres ${data.ip} (geschatte locatie: ${data.location}). Er kan maar één sessie tegelijk actief zijn. Ben jij dit niet? Neem contact op met support.`
            message.error(msg, 10)
            await getFirebase().auth().signOut()
        }
        try {
          console.log('setting isOnline')
          let { location, ip } = await this.getLocation()
          ref.set({
            isOnline: true,
            location,
            time: moment().format('DD-MM-YYYY HH:mm'),
            ip
          })
        } catch {
          ref.set({
            isOnline: true,
          })
        }
      })
      .catch( err => console.log('err', err))
    }
  }
  
  getLocation = async () => {
    try {
      let ipResult = await axios.get('https://api.ipify.org/?format=json')
      let ip = ipResult.data.ip
      let location = await axios.get(`https://ipapi.co/${ip}/json`)
      return {
        location: location.data.city,
        ip
      }
    } catch {
      return 'unknown'
    }
  }

  refreshCacheAndReload = () => {
      console.log('Clearing cache and hard reloading...')
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }

      // delete browser cache and hard reload
      window.location.reload(true);
    }

  changeLanguage = (lang, uid) => {
    if (uid) {
      getFirestore().collection('languageByUser').doc(uid).get()
        .then( snap => {
          if (snap.exists) {
            getFirestore().collection('languageByUser').doc(uid).update({lang})
          } else {
            getFirestore().collection('languageByUser').doc(uid).set({lang})
          }
        })
    }
    i18n.changeLanguage(lang)
  }

  componentDidMount() {
    fetch(`/meta.json?${Date.now()}`, { cache: "no-store" })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        console.log('versions', currentVersion, latestVersion)
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          message.loading('New version available. Reloading app...')
          setTimeout(this.refreshCacheAndReload, 3000)
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        }
      });
  }

  getRoute = (route) => {
    route.t = this.props.t
    route.changeLanguage = this.changeLanguage
    route.language = this.props.lng
    route.orderToOpen = this.state.orderToOpen
    route.setOrderToOpen = val => this.setState({orderToOpen: val})
    const Layout = layouts[route.layout] || layouts.DefaultLayout;
    return <Layout {...route}/>
  }



  render() {
    return (
      <div className="app">
        <ConfigProvider locale={nl_NL}>
          <Switch>
            {routes.map(route => this.getRoute(route))}
            <Redirect path="*" to="/"/>
          </Switch>
        </ConfigProvider>
      </div>
    )
  }
}

export default withNamespaces('test')(App)



const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
