import React from 'react'
import { Route } from 'react-router-dom'
import { Layout } from 'antd'

const { Footer } = Layout;

const ConfirmLayout = ({
  component: Component,
  ...rest
}) => {
    return (
      <Route
        {...rest}
        render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          <Component {...matchProps} t={rest.t} language={rest.language} changeLanguage={rest.changeLanguage}/>
          <Footer style={{ textAlign: 'center' }}>
              Reach ©2021
          </Footer>
        </Layout>
      )}/>
    )
};

export default ConfirmLayout;
