import React from 'react'

const LogoCollapsed = () => {
  return (
    <div className="logo-collapsed">
      
    </div>
  )
}

export default LogoCollapsed
